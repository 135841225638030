.bg-overlay::before
{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: "URL(/Assets/images/background-gradient.jpg)";
    background-repeat: no-repeat;
    background-size: cover;
    filter: grayscale(50%) blur(12px);
    backdrop-filter: grayscale(10px);
    z-index: -1;
}
.selectLang,.selectMail,.selectconstituency
{
    border: 0;
    padding: 5px 10px;
    border-radius: 10px;
    box-shadow: 0 0 7px lemonchiffon;
    font-weight: 500;
    font-size: 15px;
}
.selectLang:focus-visible,.selectMail:focus-visible,.selectconstituency:focus-visible
{
    outline: 0;
}
.send-mail
{
    border: none;
    outline: none;
    box-shadow: 0 0 7px lemonchiffon;
    border-radius: 10px;
    background-color: #15853e;
    color: white;
}
