html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #22364e;
  font-weight: 500;
}

p {
  font-family: "Open Sans", sans-serif;
  font-weight: 500;
  font-size: 14px;
  margin: 0;
}

i {
  color: #22364e;
  /* box-shadow: 0px 0px 5px gray;
  border-radius: 50%;
  padding: 5px 8px; */
}

.color {
  color: #22364e;
}

.safron,
a:hover {
  color: #ff924e;
}

.bg-green {
  background-color: #15853e;
}

.color-green {
  color: #15853e;
}

.heading-border {
  /* border-bottom: double;
  border-color: #ff924e;
  /* width: 16%; */
  /* border-width: thick; */
  margin: auto;
}

.font-14 {
  font-size: 14px;
}

.font-15 {
  font-size: 15px;
}

.font-18 {
  font-size: 18px;
}

.font-20 {
  font-size: 20px;
}

@media (max-width:768px) {
  .w-sm-100 {
    width: 100% !important;
  }
  .sm-none
  {
    display: none;
  }

  .sm-center {
    text-align: center;
  }

  .justify-center {
    justify-content: center;
  }

  .memorandum-btn {
    right: -16px !important;
  }

  .memorandum-btn button {
    font-size: 11px;
  }

  .md-position-absolute {
    display: flow;
    margin: 10px;
  }
}

@media (min-width:768px) {
  .md-position-absolute {
    position: absolute;
  }
  .md-none
  {
    display: none;
  }
}

.box-shadow {
  box-shadow: 0 0 5px gray;
  border-radius: 3%;
}

.cursor-pointer {
  cursor: pointer;
}

.img-background-1 {
  background: linear-gradient(157deg, rgb(255, 146, 78), rgb(255, 255, 255) 50%, rgb(21, 133, 78));
  padding: 4px 4px 25px 15px;
  border-radius: 2%;
}
