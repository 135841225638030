.slide_image
{
    width: 100%;
    border-radius: 20px;
    box-shadow: 0 0 2px 2px #ffa872
}
.slide_image:focus-visible
{
    outline: none;
}
.image_div
{
    padding: 10px;
}
.image_div:focus-visible
{
    outline: none;
}