.custom-col {
    flex: 0 0 20%; /* This ensures each column takes 20% of the container width */
    max-width: 20%;
  }
  
  @media (max-width: 768px) {
    .custom-col {
      flex: 0 0 100%; /* On smaller screens, make each item take up the full width */
      max-width: 100%;
    }
  }
  